import { mapData } from "@/stuff/DataMapping";

export interface IEmailDomain {
    buyerID: number;
    domain: string;
    default: boolean;
    users: number;
}

export class EmailDomain implements IEmailDomain {
    
    constructor(data?: IEmailDomain) {
        if (data) this.update(data);
    }

    update(data: IEmailDomain) {
        mapData(data, { 
            root: () => this
        });
    }

    buyerID: number = 0;
    domain: string = "";
    default: boolean = false;
    users: number = 0;

}