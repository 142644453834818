import { mapData } from "@/stuff/DataMapping";
import utils from "@/stuff/Utils";
import { ILookupItem, LookupItem } from "./LookupItem";

export interface IFindSupplierResult {
    id: number;
    name: string;
    companyType: string;
    address1: string;
    address2: string;
    address3: string;
    town: string;
    county: string;
    postcode: string;
    website: string;
    distanceMiles: number;
    distanceKilometers: number;
    isBuyerSupplier: boolean;
    schemes: Array<ILookupItem>;
}

export class FindSupplierResult implements IFindSupplierResult {

    constructor(data?:IFindSupplierResult) {
        if (data) this.update(data);
    }

    update(data:IFindSupplierResult): void {
        mapData(data, { 
            root: () => this,
            schemes: (data: ILookupItem) => new LookupItem(data)
        });
    }

    id: number = 0;
    name: string = "";
    companyType: string = "";
    address1: string = "";
    address2: string = "";
    address3: string = "";
    town: string = "";
    county: string = "";
    postcode: string = "";
    website: string = "";
    distanceMiles: number = 0;
    distanceKilometers: number = 0;
    isBuyerSupplier: boolean = false;
    schemes: Array<LookupItem> = []

    get address(): string {
        return [ this.address1, this.address2, this.address3, this.town, this.county, this.postcode ]
            .filter(str => !utils.isEmptyOrWhitespace(str))
            .join("<br />");
    }

}