
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import { FindSupplierParameters } from "@/model/FindSupplierParameters";
    import { IFindSupplierResult as ISupplier, FindSupplierResult as Supplier } from "@/model/FindSupplierResult";
    import { IBuyer, Buyer } from "@/model/Buyer";
    import { ILookupItem } from "@/model/LookupItem";
    import store from "@/store/store";
    import ApiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import * as toastr from "toastr";
    
    @Component({
        components: { ApiButton }
    })
    export default class FindASupplier extends Vue {

        async mounted(): Promise<void> {
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.parameters.buyerID = this.isBuyerZone ? +store.state.signedInUser!.buyerID : 0;

            store.dispatch("loadTradeList")
            store.dispatch("loadSchemeList") 

            if (!this.isBuyerZone) {
                this.fetchBuyers();
            }
        }

        //
        // -- properties
        //

        get trades(): Array<ILookupItem> { return this.$store.state.tradeList; }
        get schemes(): Array<ILookupItem> { return this.$store.state.schemeList; }
        get buyerTrades(): Array<ILookupItem> { return this.trades.filter(t => this.buyer.tradeIDs.includes(+t.id)); }
        get tradeOptions(): Array<ILookupItem> { return utils.selectOptions(this.buyerTrades, " "); }
        get schemeOptions(): Array<ILookupItem> { return utils.selectOptions(this.schemes, " "); }
            
        private buyerList: Array<ILookupItem> = [];
        get buyerOptions(): Array<ILookupItem> { return utils.selectOptions(this.buyerList, " "); }

        isBuyerZone: boolean = false;
        buyer: Buyer = new Buyer();
        
        parameters = new FindSupplierParameters();
        searchIsBusy: boolean = false;
        totalCount: number = 0;
        suppliers: Array<Supplier> = [];
        supplier: Supplier = new Supplier();
        showAddSupplier: boolean = false;

        infiniteId: number = 1;

        //
        // -- watchers
        //

        @Watch("parameters.buyerID")
        async onBuyerchanged() {
            await this.fetchBuyer();
        }

        //
        // -- methods
        //

        async fetchBuyers() {
            this.buyerList = await ApiClient.get("/api/buyer/lookups?supplierID=0");
        }

        async fetchBuyer() {
            if (utils.isEmptyId(this.parameters.buyerID)) {
                return; 
            }

            const response: IBuyer = await ApiClient.get(`api/buyer/load?id=${this.parameters.buyerID}`);
            this.buyer.update(response);
        }

        async refreshSearch(event?: Event) {
            if (!await this.isValid()) {
                toastr.info("Please fix the highlighted errors");
                return;
            }
        
            this.suppliers = [];
            this.parameters.pageNumber = 0;
            this.totalCount = 0;

            this.infiniteId++;
            //this.fetchNextPage(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            const isValid = await this.isValid();

            if (!isValid || (this.suppliers.length > this.totalCount && this.totalCount > -1)) {
                this.$v.$reset();
                stateChanger.complete();
                return;
            }

            this.parameters.pageNumber += 1;

            await this.fetchNextPage();

            if (this.suppliers.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        async fetchNextPage(event?: Event) {
            if (this.searchIsBusy) {
                console.log("Skipped search because busy...");
                return;
            }

            if (!await this.isValid()) {
                this.suppliers = [];
                this.parameters.pageNumber = 0;
                this.totalCount = 0;
                return;
            }

            this.searchIsBusy = true;

            const response: { count: number, list: Array<ISupplier> } = await ApiClient.post("api/supplier/findSupplier", this.parameters, event);
            if (this.parameters.pageNumber === 1) this.totalCount = response.count;
            this.suppliers.push(...response.list.map(r => new Supplier(r)));
            this.searchIsBusy = false;
        }

        clearParameters() {
            this.$v.$reset();
            utils.resetObject(this.parameters);
            this.parameters.buyerID = this.isBuyerZone ? +store.state.signedInUser!.buyerID : 0;
            this.parameters.range = 25;
            this.parameters.rangeUnit = "m";
            this.parameters.pageNumber = 0;
            this.parameters.hideBuyerSuppliers = false;
            this.suppliers = [];
            this.totalCount = 0;
        }

        website(address: string): string {
            if (utils.isEmptyOrWhitespace(address)) return "";
            if (address.startsWith("http")) return address;
            return `https://${address}`
        }

        postcodeUpper(value: string) {
            return value.toLocaleUpperCase();
        }

        viewSupplier(supplierID: number) {
            this.supplier = this.suppliers.find(s => s.id == supplierID)!;
            this.showAddSupplier = true;
            this.$bvModal.show("supplierDialogue");
        }

        hideSupplier() {
            this.$bvModal.hide("supplierDialogue");
        }

        async addSupplier(event?: Event) {
            var meesage = [ this.$createElement('div', { domProps: { innerHTML: `
<p>Are you sure you want to add ${this.supplier.name} to your organisation's supply chain?</p>
<p>A notification will be sent to CQMS - a member of the team will contact you to action this request and obtain any additional information/authorisation if necessary.</p>
<p>A notification will be sent to the supplier advising them that you want to add them to your supply chain.</p>` } }) ];

            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm(meesage, {
                title: "Add to my organisation's supply chain?",
                okTitle: "Yes, send request",
                cancelTitle: "No, cancel",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0"
            });
            
            if (!shouldDelete) return;

            const response: { success: boolean } = await ApiClient.get(`api/buyer/requestSupplier?buyerID=${this.parameters.buyerID}&supplierID=${this.supplier.id}`, event);
            if (response.success) {
                toastr.success("Request sent successfully");
                this.showAddSupplier = false;
            }
            else {
                toastr.warning("Please try again later", "Sorry, something went wrong");
            }
        }

        //
        // -- validation 
        //

        @Validations()
        validations() {
            const validations = {
                parameters: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            
            validations.parameters.postcode = { required };
            validations.parameters.tradeID = { _: (value: number) => { return !utils.isEmptyId(value) } };
            validations.parameters.range = { _: (value: number) => { return !utils.isEmptyId(value) } };
            validations.parameters.buyerID = { _: (value: number) => { return !utils.isEmptyId(value) } };

            return validations;
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        async isValid(): Promise<boolean> {
            this.$v.$reset();
            this.$v.$touch();
            await this.waitForValidation();
            return Promise.resolve(!this.$v.$error);
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        waitForValidation() {
            return new Promise(resolve => {
                if (this.$v.$error || !this.$v.$pending) {
                    return resolve("success");
                }
                const poll = setInterval(() => {
                    if (!this.$v.$pending) {
                        clearInterval(poll)
                        resolve("success")
                    }
                }, 200);
            })
        }

    }

