import utils from "@/stuff/Utils";
import { IUser, User } from "./User";
import { IEmailDomain, EmailDomain } from "./EmailDomain";
import { mapData } from "@/stuff/DataMapping";

export interface IBuyer {
    id: number;
    description: string;
    doesBuyerPay: boolean;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    moduleIDs: Array<number>;
    regionCount: number;
    userCount: number;
    
    users: Array<IUser>;
    emailDomains: Array<IEmailDomain>;
    tradeIDs: Array<number>;
}

export class Buyer implements IBuyer {

    constructor(data?: IBuyer) {
        if (data) this.update(data);
    }

    update(data: IBuyer) {
        mapData(data, { 
            root: () => this,
            users: () => new User(),
            emailDomains: () => new EmailDomain()
        });
    }

    id: number = 0;
    description: string = "";
    doesBuyerPay: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    moduleIDs: Array<number> = [];
    regionCount: number = 0;
    userCount: number = 0;
    
    users: Array<User> = [];
    emailDomains: Array<EmailDomain> = [];
    tradeIDs: Array<number> = [];
    
    get moduleCount(): number {
        return this.moduleIDs ? this.moduleIDs.length : 0;
    }

    get isNew() {
        return !this.id;
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }

    get deletedText() {
        return utils.hasDateValue(this.deleted) ? "archived" : "active";
    }
}